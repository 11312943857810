import React from 'react'
import Config from '../Config.js'
import PropTypes from 'prop-types';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import {isVideoURL, printDate, truncate} from '../utils/Util'
import {useTranslation} from "react-i18next";


function TruncatedUrl({url}) {
    return <a href={url} target="_blank" rel="noreferrer">{ truncate(url, 25) }</a>
}

TruncatedUrl.propTypes = {
    url: PropTypes.string
}

function EmbeddedVideo({ url }) {

    const sourceType = () => {
        if (url.includes("facebook.com")) {
           return "facebook" 
        } else if (url.includes("twitter.com")) {
            return "twitter"
        } else if (url.includes("youtube.com")) {
            return "youtube"
        }
    }

    const type = sourceType()

    if (type == "facebook") {
        return <TruncatedUrl url={url} />
    } else if (type == "twitter") {
        let tweetId
        try {
            const regex = /twitter.com\/.*\/([0-9]*)/gm;
            let match = regex.exec(url);
            tweetId = match[1];    
            return <TwitterTweetEmbed tweetId={tweetId} />
        } catch (e) {
            return <TruncatedUrl url={url} />
        }
    } else if (type == "youtube") {
        return <TruncatedUrl url={url} />
    }
}

EmbeddedVideo.propTypes = {
    url: PropTypes.string
}

function EventDetail({ embedVideo, event, participation, participationDenom, minWidth, showDate, classes }) {
    const {t, i18n} = useTranslation('common');
    const attrs = Config.detailAttributes
    let title = event[Config.dataEventTitleAttribute]
    if (showDate) title += ` (${printDate(event.date, i18n.language)})`
    let attrItems = []
    if (participationDenom > 0) attrItems.push(
        <li className="Participation" key="participation">Participated in {participation} of {participationDenom} protest days</li>
    )
    attrs.forEach((attr, i) => {
        let val = event[attr.column]
        if (val != null && val.length > 0) {
            let st = {}
            if (attr.color != null) st.color = attr.color;
            if (attr.type == "url") {
                if (embedVideo && isVideoURL(val)) {
                    val = <EmbeddedVideo url={val} />
                } else {
                    val = <a href={val} target="_blank" rel="noreferrer">{ truncate(val, 25, i18n.dir()) }</a>
                }
            }
            attrItems.push(<li key={i} style={st}>{t(attr.label)}: { val }</li>)
        }
    })
    const cls = classes.join(" ")
    return (
        <div className={"EventDetail " + cls} style={{minWidth: minWidth}}>
            <h2>{ title }</h2>
            <ul>
                { attrItems }
            </ul>
        </div>
    )
}

EventDetail.defaultProps = {
    classes: [],
    embedVideo: false,
    participation: 0,
    participationDenom: 0,
}

EventDetail.propTypes = {
    classes: PropTypes.array,
    embedVideo: PropTypes.bool,
    event: PropTypes.object,
    participation: PropTypes.number,
    participationDenom: PropTypes.number,
    minWidth: PropTypes.number,
    showDate: PropTypes.bool
}

export default EventDetail;