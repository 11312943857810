import React from 'react'
import Config from '../Config.js'
import LanguageSelector from './LanguageSelector.js';
import { ReactComponent as MenuIcon } from '../images/menu_icon.svg';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";


function SiteHeader({ darkBG, onMenuClick }) {
    const {t} = useTranslation('common');

    let logo
    if (Config.siteLogo != null) {
        logo = <img alt="Site Logo" className="logo" src={Config.siteLogo} />
    }

    let cls = "SiteHeader"
    if (darkBG) cls += " Dark"
    return (
        <div className={cls}>
            <MenuIcon className="MenuIcon" onClick={() => onMenuClick()} />
            <header>
                <h1>{ logo } { t("site.name") } <LanguageSelector/></h1>
            </header>
        </div>
    );
}

SiteHeader.propTypes = {
    darkBG: PropTypes.bool,
    onMenuClick: PropTypes.func
}

export default SiteHeader;