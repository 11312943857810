import React from 'react'
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../images/close.svg';

function ModalOverlay({ title, verticalPadding, onPopupClose, children }) {
    const style = {top: verticalPadding, bottom: verticalPadding}
    return (
        <>
            <div className="ModalBG" onClick={onPopupClose}></div>
            <div className="ModalOverlay" style={style}>
                <header>
                    <h1>{ title }</h1>
                    <CloseIcon onClick={onPopupClose} className="CloseIcon" />
                </header>
                <div className="Content">
                    { children }
                </div>
            </div>
        </>
    );
}

ModalOverlay.propTypes = {
    children: PropTypes.element.isRequired,
    verticalPadding: PropTypes.string,
    title: PropTypes.string,
    onPopupClose: PropTypes.func
}

ModalOverlay.defaultProps = {
    verticalPadding: "5vh",
    title: "Title",
    onPopupClose: () => {}
}



export default ModalOverlay;