const Config = {
    siteLogo: null,
    contactEmail: "sudanprotestmonitor@gmail.com",
    dataSource: "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ5VddyfxQcVLxCljyluY5srMUC1F3urhULdUG1tuC8iWODL7a7YzDmZbFQKc1zLYT1IZ5s41y3D0Q4/pub?gid=466978203&single=true&output=csv",
    dataLatAttribute: "LAT",
    dataLonAttribute: "LONG",
    dataDateAttribute: "Date",
    dataEventTitleAttribute: "City",
    shareAppUrl: "https://gisa-spm.netlify.app",
    mapTileLayer: "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
    localStorageKeyPrefix: "GisaSPM",
    useMask: true, // Save geojson data to ./data/mask.geojson
    maskBorderColor: "#3388FF",
    maskFillColor: "#e2e4e3",
    initPosition: [15.3673117, 30.1599],
    languages: {
        en: {
            key: "en",
            label: "English"
        },
        ar: {
            key: "ar",
            label: "Arabic",
            rtl: true
        }
    },
    summaryAttributes: [
        {
            label: "summary.n_days",
            column: "Date",
            aggregation: "count_unique",
            color: "#203061",
            hideWhenDateSelected: true
        },
        // {
        //     label: "summary.protests",
        //     aggregation: "count",
        //     color: "#203061"
        // },
        {
            label: "summary.n_locations",
            column: "position",
            aggregation: "count_unique",
            color: "#203061"
        },
        {
            label: "summary.injured",
            column: "Injuries",
            aggregation: "sum",
            color: "#daa136"
        },
        {
            label: "summary.dead",
            column: "Fatalities",
            aggregation: "sum",
            color: "#daa136"
        },
    ],
    detailAttributes: [
        {
            label: "details.protestors",
            column: "Protestors",
        },
        {
            label: "details.injured",
            column: "Injuries",
            color: "#daa136"
        },
        {
            label: "details.dead",
            column: "Fatalities",
            color: "#daa136"
        },
        {
            label: "details.link",
            column: "Link1",
            type: "url"
        },
        {
            label: "details.link",
            column: "Link2",
            type: "url"
        }        
    ],
    scrubMarkerSizeRange: [10, 60], // Pixels
    mapMarkerSizeRange: [5, 30], // Leaflet marker size
    mapMarkerSizeFixed: 10,  // 0 if not fixed
    scrubMarkerColor: "#31693f",
    scrubMarkerFocusColor: "#34ba56",
    scrubMarkerScrubColor: "#628069",
    timelineDateColor: "#31693f",
    markerColor: "#0000BB",
    eventMarkerColorRules: [
        {
            column: "Injuries",
            rule: [">", 0],
            color: "#daa136"
        },
        {
            column: "Fatalities",
            rule: [">", 0],
            color: "#daa136"
        },
        {
            color: "#203061"
        }
    ],
    scrubberStartDate: "2021-10-21",
    scrubberEndDate: "2022-10-25",
    scrubberKeyDates: [
        {
            date: "2021-10-25",
            label: "Coup",
            showDuringScrub: true
        },
        {
            date: "2022-10-25",
            label: "Anniversary",
            showDuringScrub: true
        }
    ]
}

export default Config;