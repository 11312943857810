import Config from '../Config.js'
import { DateTime } from "luxon";

const authenticateEsri = (client_id, client_secret, cb) => {
    const EXP = 1800
    const authservice = "https://www.arcgis.com/sharing/rest/oauth2/token";
    const url = `${authservice}?client_id=${client_id}&client_secret=${client_secret}&grant_type=client_credentials&expiration=${EXP}`;
  
    let token;
  
    fetch(url, {
      method: "POST"
    })
      .then((res) => res.json())
      .then((res) => {
        token = res.access_token;
        cb(token)
      })
      .catch((error) => {
        console.error(error);
      });
}

const clamp = (val, min, max) => {
    return Math.min(Math.max(val, min), max);
}

const locationKey = (lat, lon) => {
    return `${lat}_${lon}`
}

const dateDiff = (d1, d2) => {
    return d2.diff(d1, 'days').days;
}

const dateInTZ = function(tz = null) {
    if (tz == null) tz = "UTC";
    let d = new Date();
    d = new Date(d.toLocaleDateString("en-US", {timeZone: tz}));
    return d
}

const dateFromISOString = (isoString) => {
    return DateTime.fromISO(isoString)
}

const isoDateString = function(d) {
    if (d == null) {
      // Get days since epoch according to current PST time
      d = DateTime.now()
    }
    return d.toFormat("yyyy-MM-dd")
}

const printDate = (d, _lang, weekday = "short") => {
    var options = { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric'
    };
    if (weekday != null) options.weekday = weekday
    return d.toLocaleString(options)
}

const isVideoURL = function(url) {
    if (url.includes("facebook.com")) {
        return "facebook" 
    } else if (url.includes("twitter.com")) {
        return "twitter"
    } else if (url.includes("youtube.com")) {
        return "youtube"
    }
    return null;
}

const truncate = (s, len, dir="ltr") => {
    if (s == null) return s
    if (s.length > len) {
        if (dir == "ltr") return s.substring(0, len) + "..."
        else if (dir == "rtl") return "..." + s.substring(0, len)
    }
    else return s
}

const sameDay = function(d1, d2) {
    if (d1 === null || d2 === null) return false
    return dateDiff(d1, d2) === 0
}

const percentToMarkerSize = (pct, min, max) => {
    // Lerp
    // return pct * (max - min) + min
    // Log
    if (isNaN(pct)) pct = 0;
    return clamp(Math.log(pct * 100 + 1) * 20, min, max)
}

const validLocation = (lat, lon) => {
    return lat != null && lon != null && !isNaN(lat) && !isNaN(lon)
}

const logicPasses = (value, operator, operand) => {
    if (operator == ">") return value > operand;
    else if (operator == ">=") return value >= operand;
    else if (operator == "<") return value < operand;
    else if (operator == "<=") return value <= operand;
    else if (operator == "!=") return value != operand;
    else if (operator == "=") return value == operand;
    return false;
}

const highlightFor = (id,color,ms) => {
    const element = document.getElementById(id)
    if (element != null) {
        var origcolor = element.style.color
        element.style.color = color;
        setTimeout(() => {
           element.style.color = origcolor;
        }, ms);    
    }
}

const lsGet = (key) => {
    // Get from local storage
    return localStorage.getItem(Config.localStorageKeyPrefix + "_" + key);
}

const lsSet = (key, value) => {
    // Set in local storage
    localStorage.setItem(Config.localStorageKeyPrefix + "_" + key, value);
}

export {
    authenticateEsri,
    clamp,
    dateDiff,
    dateFromISOString,
    dateInTZ,
    highlightFor,
    isoDateString,
    isVideoURL,
    locationKey,
    logicPasses,
    lsGet,
    lsSet,
    percentToMarkerSize,
    printDate,
    sameDay,
    truncate,
    validLocation
}