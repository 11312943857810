import React, { useEffect } from 'react'
import ModalOverlay from './ModalOverlay'
import LanguageSelector from './LanguageSelector.js';
import PropTypes from 'prop-types';
import { lsSet } from '../utils/Util';
import {useTranslation, Trans} from "react-i18next";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
  } from "react-share";
import SPMLogo from '../images/spm_logo_colored.png';
import Config from '../Config';


function ContactLink({ text }) {
    let linkText = text == null ? Config.contactEmail : text
    return <a href={`mailto:${Config.contactEmail}`}>{linkText}</a>
}

ContactLink.propTypes = {
    text: PropTypes.string
}

function LinkText (props) {
    return (
        <a href={props.to || '#'} target="_blank" rel="noreferrer" title={props.title || ''}>
            {props.children}
        </a>
    );
}

LinkText.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    to: PropTypes.string
}

function Introduction({ open, onOptionSelected, onPopupClose }) {
    const {t} = useTranslation('common');

    useEffect(() => {
        if (open) {
            // Set key to store intro is read
            lsSet("vIntro", true)
        }
    }, [])

    if (!open) return

    return (
        <ModalOverlay title={t('introduction.title')} onPopupClose={onPopupClose}>
            <div className="Introduction">
                {t('introduction.language')}: <LanguageSelector />

                <img src={SPMLogo} className="Logo" />

                <p>{t("introduction.p1")}</p>

                <p>{t("introduction.actions")}:</p>

                <div className="Actions">
                    <button onClick={() => onOptionSelected('explore')} className="StandardButton">{ t('introduction.action_launch')}</button>
                    <button onClick={() => onOptionSelected('walkthrough')} className="StandardButton">{ t('introduction.action_explore')}</button>
                    <button onClick={() => onOptionSelected('contribute')} className="StandardButton">{ t('introduction.action_contribute')}</button>
                </div>

                <h2>{t("introduction.context")}</h2>
                
                <p>{t("introduction.p2_context_1")}</p>

                <p>{t("introduction.p3_context_2")}</p>

                <h2>{t("introduction.about")}</h2>

                <p>
                    <Trans i18nKey="introduction.p4_about" t={t}
                          components={{
                            link1: <LinkText to="https://3ayin.com/" />,
                            link2: <LinkText to="https://sudanesearchive.org/en/datasets/coup-files" />,
                            link3: <LinkText to="https://www.theccsd.org/" />
                          }}>--</Trans>
                </p>
                {/* <p>The Sudan Protest Monitor is a special project managed by the independent media organization <a href="https://3ayin.com/">Ayin</a>. SPM monitors the size and location of all major protests as well as violence against civilians by security forces using credible, but largely unverified visual documentation created by citizens across the country.  This data is gathered from the <a href="https://sudanesearchive.org/en/datasets/coup-files">Sudanese Archive coup files archive</a> which includes over 100,000 photos and videos.  Our medical data comes from the <a href="https://www.theccsd.org/">Central Committee of Sudanese Doctors</a>. Dozens of civil society groups working across Sudan and internationally also contribute information. The current data set stretches from October. 25th 2021 to October 25th, 2022. We hope to update this data set monthly. All data is openly available upon request.</p> */}

                <h2>{t("introduction.support")}</h2>

                <p>{t("introduction.p5_support")}</p>
                {/* <p>Help The Sudan Protest Monitor is a work in progress. We are actively looking for additional videos and photos, major protest days that were not included, and especially protests that we missed outside of Khartoum. We are also working to geolocate, and continuously improve the quality of our data. Submit material or inaccuracies and help us fill in the gaps.</p> */}

                <button onClick={() => onOptionSelected('contribute')} className="StandardButton">{t('introduction.btn_submit')}</button>

                <p>{t("introduction.contact_info")}: <ContactLink /></p>

                <p>{t("introduction.share_cta")}</p>

                <EmailShareButton url={Config.shareAppUrl} subject={t("social.subject")} > 
                    <EmailIcon />
                </EmailShareButton>
                <FacebookShareButton url={Config.shareAppUrl}>
                    <FacebookIcon />
                </FacebookShareButton>
                <TelegramShareButton url={Config.shareAppUrl} title={t("social.subject")}>
                    <TelegramIcon />
                </TelegramShareButton>
                <TwitterShareButton url={Config.shareAppUrl} title={t("social.subject")} via={t("site.name")}>
                    <TwitterIcon />
                </TwitterShareButton>
                <WhatsappShareButton url={Config.shareAppUrl} title={t("social.subject")}>
                    <WhatsappIcon />
                </WhatsappShareButton>

            </div>
        </ModalOverlay>
    );
}

Introduction.defaultProps = {
    open: false,
    onPopupClose: () => {}
}

Introduction.propTypes = {
    open: PropTypes.bool,
    onOptionSelected: PropTypes.func,
    onPopupClose: PropTypes.func
}

export default Introduction;