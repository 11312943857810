import React, {useState} from 'react'
import ModalOverlay from './ModalOverlay'
import PropTypes from 'prop-types';

function Contribute({ open, onPopupClose }) {
    const [message, setMessage] = useState(null);

    // const {t} = useTranslation('common');

    if (!open) return

    const handleSubmit = (event) => {
        event.preventDefault();
      
        const myForm = event.target;
        const formData = new FormData(myForm);
        
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString(),
        })
          .then(() => {
            setMessage("Thank you for your report.")
            console.log("Form successfully submitted")
          })
          .catch((error) => alert(error));
    };

    const handlePopupClose = () => {
        setMessage(null)
        onPopupClose()
    }

    const showForm = message == null;
    const content = showForm ? (
        <div>
            To contribute missing data, or suggest revisions, please enter the following details:
            <form name="contribute" method="POST" data-netlify="true" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contribute" />
                <p>
                    <label>Protest date: <input type="date" name="date" /></label>
                </p>
                <p>
                    <label>Protest location: <input type="text" name="location" /></label>
                </p>
                <p>
                    <label>Any links to videos or supporting coverage:<br/>
                    <textarea type="text" name="links"></textarea></label>
                </p>
                <p>
                    <label>Additional protest details:<br/><textarea type="text" name="details"></textarea></label>
                </p>

                <button type="submit" className="StandardButton">Send</button>
            </form>
        </div>
        ) : <div className="Message">{ message }</div>;
    return (
        <ModalOverlay title="Contribute" onPopupClose={handlePopupClose}>
            <div className="Contribute">

                { content }

            </div>
        </ModalOverlay>
    );
}

Contribute.defaultProps = {
    open: false,
    onPopupClose: () => {}
}

Contribute.propTypes = {
    open: PropTypes.bool,
    onPopupClose: PropTypes.func
}

export default Contribute;