import React, { useEffect, useState } from 'react'
import Config from '../Config.js'
import PropTypes from 'prop-types';
import {printDate} from '../utils/Util'
import {useTranslation} from "react-i18next";
import EventListIcon from '../images/event_list_icon.png';

function SummaryTable({ events, filterDate, onClick }) {
    const {t, i18n} = useTranslation('common');
    const [stats, setStats] = useState({})

    let attrs = Config.summaryAttributes

    if (filterDate != null) {
        // Remove "days" attribute which always shows 1
        // TODO
        attrs = attrs.filter((attr) => {
            return !attr.hideWhenDateSelected
        })
    }

    useEffect(() => {
        recalculateStats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events])

    // useEffect(() => {
    //     highlightFor("summaryDateSpan", "orange", 200)
    // }, [filterDate])

    const recalculateStats = () => {
        const values = {}
        const unique_counts = {}
        attrs.forEach((a) => {
            if (a.aggregation == "count_unique") {
                // Set up a dict to track unique values
                unique_counts[a.label] = {}
            }
            values[a.label] = 0
        })

        events.forEach((e) => {
            attrs.forEach((attr) => {
                const key = attr.label
                if (attr.aggregation === "count") values[key] += 1
                else if (attr.aggregation === "count_unique") {
                    let unique_val_key = e[attr.column]
                    unique_counts[key][unique_val_key] = 1
                } else if (attr.aggregation === "sum") values[key] += parseInt(e[attr.column]) || 0
            })
        })
        // Compute counts for count_unique attributes
        Object.keys(unique_counts).forEach((key) => {
            // Count is # of keys in object
            values[key] = Object.keys(unique_counts[key]).length
        })
        setStats(values)
    }

    const renderMainLabel = () => {
        if (events.length === 0) {
            return t("summary.no_events")
        } else if (filterDate == null) {
            // By default, show "Since [date]" where date is oldest event
            const oldest = printDate(events[0].date, i18n.language);
            return t("summary.since_date", {date: oldest})
        } else {
            return <>{t("summary.on_date")} <span id="summaryDateSpan" style={{color: Config.timelineDateColor, fontWeight: "bold"}}>{printDate(filterDate)}</span></>
        }
    }  

    const renderAttributes = () => {
        const colPercent = 100 / attrs.length
        return attrs.map((attr, i) => {
            let color = attr.color || "black";
            return (
                <div className="attributeColumn" key={i} style={{color: color, width: `${colPercent}%`}}>
                    <span className="attributeValue">{ stats[attr.label] }</span>
                    <span className="attributeLabel">{ t(attr.label) }</span>
                </div>
            )
        })
    }

    return (
        <div className="SummaryTable" onClick={() => onClick()}>
            <div className="MainLabel">{ renderMainLabel() }</div>
            <div>{ renderAttributes() }</div>
            <div className="LinkText"><a href="#"><img src={EventListIcon} /> { t("summary.event_list") }</a></div>
        </div>
    );
}

SummaryTable.defaultProps = {
    events: [],
    filterDate: null,
    onClick: () => {}
}

SummaryTable.propTypes = {
    events: PropTypes.array,
    filterDate: PropTypes.instanceOf(Object),
    onClick: PropTypes.func
}

export default SummaryTable;