import React, { useEffect, useState } from 'react'
import { MapContainer, CircleMarker, Popup, TileLayer,
    Marker, useMapEvents } from 'react-leaflet'
// import VectorBasemapLayer from "react-esri-leaflet/plugins/VectorBasemapLayer";
import MarkerClusterGroup from 'react-leaflet-cluster'
import PropTypes from 'prop-types';
import setupLeafletMask from '../plugins/LeafletMask'
import maskData from '../data/mask.geojson'
import EventDetail from '../components/EventDetail'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconRetinaMarker from 'leaflet/dist/images/marker-icon-2x.png';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import Config from '../Config';
import {locationKey} from '../utils/Util';

setupLeafletMask(L)

// Fix react-leaflet CSS problems, cf: https://github.com/PaulLeCam/react-leaflet/issues/453
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetinaMarker,
    iconUrl: iconMarker,
    shadowUrl: iconShadow
});


function MapEventHandler({ onExtentChanged, onPopupOpened }) {
    const map = useMapEvents({
        moveend: () => {
            onExtentChanged(map.getBounds())
        },
        zoomend: () => {
            onExtentChanged(map.getBounds())
        },
        popupopen: (e) => {
            const ei = e.popup.options.eventIndex
            onPopupOpened(ei)
        }
    })

    useEffect(() => {
        // Hackish solution to useMapEvents `load` not working as per: https://github.com/PaulLeCam/react-leaflet/issues/46
        onExtentChanged(map.getBounds());

        // Setup mask if configured
        if (Config.useMask) {
            let maskOptions = {
                fitBounds: false,
                restrictBounds: false,
                fillOpacity: 0.6,
                opacity: 0.5,
            }
            L.mask(maskData, maskOptions).addTo(map);
        }
    }, [])
}

function Map({ events, locationStats, useCircleMarkers,  
               clusteringEnabled, onExtentChanged }) {
    // const [markers, setMarkers] = useState({})
    // const {i18n} = useTranslation('common');
    
    // const [esriToken, setEsriToken] = useState(null);
    const [eventPopupOpen, setEventPopupOpen] = useState(-1);
    
    // useEffect(() => {
    //     console.log("Authenticating with ESRI...")
    //     authenticateEsri(process.env.REACT_APP_ESRI_CLIENT_ID,
    //         process.env.REACT_APP_ESRI_CLIENT_SECRET, (token) => {
    //             console.log(`Got ESRI token.`)
    //             setEsriToken(token)
    //         })        
    // }, [])

    const handlePopupOpenened = (eventIndex) => {
        setEventPopupOpen(eventIndex)
    }

    const renderMarkers = () => {
        return events.map((ev) => {
            let radius = 1;
            // if (Config.mapMarkerSizeFixed == 0) {
            //     const pct = m.count / events.length;
            //     const [min, max] = Config.scrubMarkerSizeRange;
            //     radius = percentToMarkerSize(pct, min, max);    
            // } else {
            radius = Config.mapMarkerSizeFixed;
            // }
            let MarkerObj;
            let markerProps = {}
            if (useCircleMarkers) {
                MarkerObj = CircleMarker
                markerProps.center = ev.position
                markerProps.radius = radius
                markerProps.color = ev.color
            } else {
                MarkerObj = Marker
                markerProps.position = ev.position
            }
            const popupOpen = ev.index == eventPopupOpen
            let lkey = locationKey(ev.position[0], ev.position[1])
            let participationCount = 0
            if (locationStats[lkey] == null) {
                console.log(`Can't find lkey: ${lkey}`)
            } else {
                participationCount = locationStats[lkey]['dates'].length
            }
            return (
                <MarkerObj key={ev.index} {...markerProps}>
                    <Popup style={{maxHeight: 400}} eventIndex={ev.index}>
                        { popupOpen ? <EventDetail event={ev} 
                                                   participation={participationCount} 
                                                   participationDenom={locationStats['nDates']} 
                                                   showDate={true} 
                                                   minWidth={250} 
                                                   embedVideo={true} /> : null }
                    </Popup>
                </MarkerObj>
            )
        })
    }

    const createClusterCustomIcon = function (cluster) {
        const n = cluster.getChildCount()
        let intensity = 'Low'
        if (n > 100) intensity = 'High'
        else if (n > 10) intensity = 'Mid'
        return L.divIcon({
          html: `<span>${cluster.getChildCount()}</span>`,
          className: `MarkerCluster ${intensity}`,
          iconSize: L.point(33, 33, true),
        })
    }

    // if (esriToken === null) return

    return (
        <MapContainer center={Config.initPosition} 
                      zoom={5} 
                      maxZoom={15}
                      scrollWheelZoom={true}
                      zoomControl={false}
                      whenCreated={map => {map.onLoad = (map) => {
                        console.log("On load")
                        onExtentChanged(map)
                      }}}>
            {/* <ZoomControl position="bottomleft" /> */}
            <MapEventHandler onExtentChanged={onExtentChanged} onPopupOpened={handlePopupOpenened} />
            {/* <VectorBasemapLayer name="ArcGIS:Newspaper" token={esriToken} /> */}
            <TileLayer
                attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a>'
                url={Config.mapTileLayer}
                />

            { clusteringEnabled ?
            <MarkerClusterGroup chunkedLoading spiderfyOnMaxZoom={true} showCoverageOnHover={false}
                                iconCreateFunction={createClusterCustomIcon}
                                maxClusterRadius={40}
                                polygonOptions={{
                                    fillColor: '#ffffff',
                                    color: '#f00800',
                                    weight: 5,
                                    opacity: 1,
                                    fillOpacity: 0.7,
                                  }}>
                { renderMarkers() }
            </MarkerClusterGroup> : renderMarkers() }

        </MapContainer>
    );
}

Map.defaultProps = {
    clusteringEnabled: false,
    // deduplicateOverlappingMarkers: false,
    events: [],
    useCircleMarkers: true,
    onExtentChanged: () => {}
}

Map.propTypes = {
    clusteringEnabled: PropTypes.bool,
    // deduplicateOverlappingMarkers: PropTypes.bool,
    events: PropTypes.array,
    locationStats: PropTypes.object,
    useCircleMarkers: PropTypes.bool,
    onExtentChanged: PropTypes.func,
    onPanned: PropTypes.func
}

export default Map;