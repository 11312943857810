import React, { useEffect, useState, useMemo } from 'react'
import Papa from 'papaparse';

import Map from './views/Map';
import SiteHeader from './components/SiteHeader';
import SummaryTable from './components/SummaryTable'
import Introduction from './components/Introduction';
import Contribute from './components/Contribute';
import ScrubTimeline from './components/ScrubTimeline';
import Walkthrough from './components/Walkthrough';
import ModalMenu from './components/ModalMenu';
import EventList from './components/EventList';
import Config from './Config.js'
import milestoneData from './data/milestones.json'
import debounce from 'lodash.debounce';
// import { DateTime } from "luxon";
import {dateFromISOString, sameDay, locationKey, logicPasses, lsGet, 
    printDate, validLocation, isoDateString} from './utils/Util'
import {useTranslation} from "react-i18next";
import './App.css';

function App() {
    const viewedIntro = lsGet("vIntro");
    const savedLang = lsGet("lang") || "en";
    const DEFAULT_MODE = viewedIntro ? "explore" : "intro";
    const {i18n} = useTranslation('common');
    const [events, setEvents] = useState([])
    const [locationStats, setLocationStats] = useState({})
    const [modalMenuOpen, setModalMenuOpen] = useState(false)
    const [mode, setMode] = useState(DEFAULT_MODE)
    const [mapBounds, setMapBounds] = useState(null)
    const [filteredEventIdxs, setFilteredEventIdxs] = useState([])
    const [filterDate, setFilterDate] = useState(null)
    const [eventListShowing, setEventListShowing] = useState(false)

    useEffect(() => {
        console.log(process.env.REACT_APP_ESRI_CLIENT_ID)
        loadData()
        console.log(`Setting default language to: ${savedLang}`)
        i18n.changeLanguage(savedLang)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Update filtered event indices when bounds or filter date change
        filterEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapBounds, filterDate, events])
    

    const eventMarkerColor = (ev) => {
        for (let i=0; i<Config.eventMarkerColorRules.length; i++) {
            let rule = Config.eventMarkerColorRules[i];
            let is_default = rule.rule == null;
            if (is_default) return rule.color;
            let [operator, operand] = rule.rule;
            if (logicPasses(ev[rule.column], operator, operand)) {
                return rule.color;
            }
        }
        return "black";
    }

    const loadData = () => {
        Papa.parse(Config.dataSource, {
            download: true,
            header: true,
            complete: function(results) {
                const ev = []
                const dateCounter = {}
                results.data.sort((a, b) => new Date(a.Date) - new Date(b.Date)).forEach((e, i) => {
                    let lat = parseFloat(e.LAT)
                    let lon = parseFloat(e.LONG);
                    e.index = i
                    e.position = [lat, lon]
                    e.date = dateFromISOString(e[Config.dataDateAttribute])
                    e.label = e[Config.dataEventTitleAttribute]
                    e.label += ` (${printDate(e.date, i18n.language)})`
                    e.color = eventMarkerColor(e)
                    if (validLocation(lat, lon)) {
                        // Store list of dates for each location
                        let locKey = locationKey(lat, lon)
                        let dateStr = isoDateString(e.date)
                        if (locationStats[locKey] == null) {
                            locationStats[locKey] = {'dates': []}
                        }
                        if (locationStats[locKey]['dates'].indexOf(dateStr) == -1) {
                            // Count only unique dates
                            locationStats[locKey]['dates'].push(dateStr)
                        }
                        dateCounter[dateStr] = 1

                        // Add to main events array
                        ev.push(e)
                    }
                })
                setEvents(ev)
                locationStats['nDates'] = Object.keys(dateCounter).length
                setLocationStats(locationStats)
            }
        })
    }

    const filterEvents = () => {
        // Find subset of events that are within current map bounds
        // If dateFilter is non-null, also filter to events on selected date
        // Sets array containing index of each event that passes criteria in state
        console.log("filterEvents")
        if (mapBounds != null) {
            let eventIdxs = []
            events.forEach((e, idx) => {
                const boundsOK = mapBounds.contains(e.position)
                if (boundsOK) eventIdxs.push(idx)
            })
            setFilteredEventIdxs(eventIdxs)    
        }
    }

    const handleExtentChange = (bounds) => {
        setMapBounds(bounds)
    }

    const handleMenuClick = () => {
        setModalMenuOpen(!modalMenuOpen)
    }

    const handleMenuOptionSelected = (key) => {
        setModalMenuOpen(false)
        if (key == null) key = "explore"
        console.log(`Mode -> ${key}`)
        setMode(key)
    }

    const resetMode = () => {
        setMode(DEFAULT_MODE)
    }

    const getExtentEvents = () => {
        return filteredEventIdxs.map(idx => events[idx])
    }
    
    const getFilteredExtentEvents = () => {
        return filteredEventIdxs.map(idx => events[idx]).filter((e) => {
            return filterDate === null || sameDay(e.date, filterDate)
        })
    }

    const handleMilestoneChange = (milestone) => {
        setFilterDate(dateFromISOString(milestone.date))
    }

    const handleDateSelected = (date) => {
        // console.log(`Date selected: ${date}`)
        setFilterDate(date)
    }

    const debouncedDateSelectedHandler = useMemo(
        () => debounce(handleDateSelected, 300)
    , [])    

    const rtl = Config.languages[i18n.language].rtl
    const haveData = events.length > 0
    const filteredExtentEvents = getFilteredExtentEvents()
    const extentEvents = getExtentEvents()
    let cls = "App"
    if (rtl) cls += " RTL"

    return (
        <div className={cls}>
            <SiteHeader onMenuClick={handleMenuClick} darkBG={mode === "walkthrough"}/>

            { haveData ? 
                <>
                    <ModalMenu open={modalMenuOpen} 
                               currentMode={mode}
                               onOptionSelected={handleMenuOptionSelected} />
                    <Introduction open={mode === "intro"} 
                                  onOptionSelected={handleMenuOptionSelected}
                                  onPopupClose={resetMode} />
                    <Contribute open={mode === "contribute"} onPopupClose={resetMode} />
                    <Walkthrough open={mode === "walkthrough"} milestones={milestoneData} 
                                 onMilestoneChange={handleMilestoneChange}
                                 onClose={resetMode} />
                    <SummaryTable filterDate={filterDate} events={filteredExtentEvents}
                                  onClick={() => setEventListShowing(!eventListShowing)} />
                    <ScrubTimeline filterDate={filterDate} events={extentEvents}
                            onDateSelected={(d) => debouncedDateSelectedHandler(d)}
                            walkthroughOpen={mode === "walkthrough"}
                            top={mode === "walkthrough" ? "215px" : "60px"} />
                    <EventList events={filteredExtentEvents} filterDate={filterDate} 
                            showing={eventListShowing} onPopupClose={() => setEventListShowing(false)} />
                </> : null 
            }

            <Map events={filteredExtentEvents} 
                 locationStats={locationStats} 
                 clusteringEnabled={filterDate == null} 
                 onExtentChanged={handleExtentChange} />
        </div>
        );
    }
    
    export default App;
    