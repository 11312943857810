import React from 'react'
import Config from '../Config.js'
import {useTranslation} from "react-i18next";
import { lsSet } from '../utils/Util.js';

function LanguageSelector() {
    const {i18n} = useTranslation('common');

    const setLanguage = (langKey) => {
        lsSet("lang", langKey)
        i18n.changeLanguage(langKey)
    }

    const langs = Object.keys(Config.languages).map((langKey, i) => {
        const sel = langKey === i18n.language
        let cls = "lang"
        if (sel) cls += " sel"
        return <button className={cls} key={i} onClick={() => setLanguage(langKey)}>{ langKey }</button>
    })

    return <span className="LanguageSelector">{ langs }</span>

}

export default LanguageSelector;