import React, {useEffect} from 'react'
import ModalOverlay from './ModalOverlay'
import EventDetail from '../components/EventDetail'
import PropTypes from 'prop-types';
import {printDate} from '../utils/Util'
import {useTranslation} from "react-i18next";
import { DateTime } from "luxon";


function EventList({ events, filterDate, showing, onPopupClose }) {
    const {t} = useTranslation('common');

    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Escape') {
            event.preventDefault();
            onPopupClose();
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        // 👇️ clean up event listener
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    if (!showing) return

    const listHeader = filterDate == null ? t("details.events") : t("details.events_on", {date: printDate(filterDate)})

    return (
        <ModalOverlay title={listHeader} onPopupClose={onPopupClose}>
            <div className="EventList">
                { events.map((e, i) => <EventDetail key={i} classes={["WithDivider"]} showDate={filterDate == null} event={e} />) }
            </div>
        </ModalOverlay>
    );
}

EventList.defaultProps = {
    events: [],
    filterDate: null,
    showing: false,
    onPopupClose: () => {}
}

EventList.propTypes = {
    events: PropTypes.array,
    filterDate: PropTypes.instanceOf(DateTime),
    showing: PropTypes.bool,
    onPopupClose: PropTypes.func
}

export default EventList;