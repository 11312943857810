import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import {ReactComponent as RightArrow} from '../images/right_arrow.svg';
import {printDate, dateFromISOString} from '../utils/Util'
import {useTranslation} from "react-i18next";

// import {dateDiff, printDate, sameDay, percentToMarkerSize} from '../utils/Util'
// import Config from '../Config.js'
// import {useTranslation} from "react-i18next";
// import ResetIcon from '../images/reset.png';

function CloseButton({ label, onClose }) {
    return (
        <button className="CloseButton" onClick={onClose}>{ label }</button>
    )
}

CloseButton.propTypes = {
    label: PropTypes.string,
    onClose: PropTypes.func
}

function MoreButton({url}) {
    return (
        <a className="MoreButton" href={url} target="_blank" rel="noreferrer">More</a>
    )
}

MoreButton.propTypes = {
    url: PropTypes.string
}

function ShiftButton({direction, onShift}) {

    const label = direction == 1 ? "Next" : "Last";
    return (
        <RightArrow className={`ShiftButton ${label}`} onClick={() => onShift(direction)}>{ label }</RightArrow>
    )
}

ShiftButton.propTypes = {
    direction: PropTypes.number,
    onShift: PropTypes.func
}


function WalkthroughHeader({ milestones, index, language, onShift }) {
    const {t} = useTranslation('common')
    const milestone = milestones[index]
    const name = milestone.name[language]
    return (
        <div className="WalkthroughHeader">
            <ShiftButton direction={-1} onShift={onShift} />
            <ShiftButton direction={1} onShift={onShift} />
            <div className="EventIndex">Featured Event {index+1} of {milestones.length}</div>
            <div className="Date">{ printDate(dateFromISOString(milestone.date)) }</div>
            { milestone.name != null ? <div className="Theme">{t('milestone.theme')}: <span className="ThemeValue">{ name }</span></div> : null }
        </div>
    )
}

WalkthroughHeader.propTypes = {
    milestones: PropTypes.array,
    index: PropTypes.number,
    language: PropTypes.string,
    onShift: PropTypes.func
}

function Walkthrough({ open, milestones, onMilestoneChange, onClose }) {
    const [index, setIndex] = useState(0)
    const {t, i18n} = useTranslation('common');

    useEffect(() => {
        if (open) {
            // Opened, call onMilestoneChange
            onMilestoneChange(milestones[index])    
        }
    }, [open])

    const handleShift = (delta) => {
        let new_index = index + delta
        if (new_index >= milestones.length) new_index = 0
        if (new_index < 0) new_index = milestones.length - 1
        setIndex(new_index)
        onMilestoneChange(milestones[new_index])
    }

    const handleClose = () => {
        onClose()
    }

    if (!open) return <></>

    const milestone = milestones[index]
    const st = {}
    if (milestone.image != null && milestone.image.length > 0) {
        st.backgroundImage = `url(/milestoneImages/${milestone.image})`
        st.backgroundSize = "cover"
        st.backgroundPosition = "50% 50%"
        st.backgroundRepeat = "no-repeat"
    }
    let moreButton = null;
    if (milestone.moreUrl != null) {
        moreButton = <MoreButton url={milestone.moreUrl[i18n.language]} />
    }
    const text = milestone.text[i18n.language]
    return (
        <div className="Walkthrough" style={st}>
            <WalkthroughHeader milestones={milestones} language={i18n.language} index={index} onShift={handleShift} />
            <div className="Text">{ text } { moreButton }</div>
            <CloseButton onClose={handleClose} label={t('milestone.close')} />
        </div>
    );
}

Walkthrough.defaultProps = {
    milestones: [],
    open: false,
    onMilestoneChange: null,
    onClose: null
}

Walkthrough.propTypes = {
    milestones: PropTypes.array,
    open: PropTypes.bool,
    onMilestoneChange: PropTypes.func,
    onClose: PropTypes.func
}

export default Walkthrough;