import React from 'react'
import PropTypes from 'prop-types';

import ModalOverlay from './ModalOverlay'
import {useTranslation} from "react-i18next";

const MENU_OPTIONS = [
    {
        key: "intro",
        label: "menu.intro"
    },
    {
        key: "walkthrough",
        label: "menu.important_protests"
    },
    {
        key: "explore",
        label: "menu.explore"
    },
    {
        key: "contribute",
        label: "menu.contribute"
    },
    {
        key: "ayin",
        label: "menu.ayin",
        goto: "https://3ayin.com/"
    }
]

function ModalMenu({ currentMode, open, onOptionSelected }) {
    const {t} = useTranslation('common')

    if (!open) return <></>

    const handleClick = (e, mo) => {
        e.preventDefault()
        if (mo.goto != null) window.open(mo.goto, '_blank');
        else onOptionSelected(mo.key)
        return false;        
    }

    const handlePopupClose = () => {
        onOptionSelected(null)
    }

    return (
        <ModalOverlay verticalPadding="20vh" title={t('menu.title')} onPopupClose={handlePopupClose}>
            <div className="ModalMenu">
                <ul>
                    { MENU_OPTIONS.map((mo) => {
                        let current = mo.key == currentMode
                        const cls = current ? "Current" : ""
                        return <li key={mo.key} className={cls}><a href="#" onClick={(e) => handleClick(e, mo)}>{ t(mo.label) }</a></li>
                    })}
                </ul>
            </div>
        </ModalOverlay>
    );
}

ModalMenu.propTypes = {
    currentMode: PropTypes.string,
    open: PropTypes.bool,
    onOptionSelected: PropTypes.func
}

ModalMenu.defaultProps = {
    open: false,
    onOptionSelected: null
}



export default ModalMenu;