import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import {dateDiff, dateFromISOString, isoDateString, printDate, sameDay, percentToMarkerSize} from '../utils/Util'
import Config from '../Config.js'
import { DateTime } from "luxon";
import {useTranslation} from "react-i18next";
import { ReactComponent as ResetIcon } from '../images/reset_calendar.svg';
import {ReactComponent as RightArrow} from '../images/right_arrow.svg';

function ScrubMarker({ anyDateSelected, date, dayIndex, maxIndex, size, selected, 
        scrubbing, keyDate }) {
    const {i18n} = useTranslation('common');
    const margin = -size / 2;
    const top = `${100*dayIndex/maxIndex}%`;
    let showKeyDate = keyDate != null && keyDate.showDuringScrub
    const showLabel = scrubbing && (selected || showKeyDate)

    const markerColor = (scrubbing, selected) => {
        if (selected) return Config.scrubMarkerFocusColor;
        else if (scrubbing) return Config.scrubMarkerScrubColor;
        else if (anyDateSelected) return Config.scrubMarkerScrubColor;
        else return Config.scrubMarkerColor;
    }

    const labelSt = {
        top: top,
    };
    const markerSt = {
        top: top,
        borderRadius: size/2, 
        margin: margin, 
        width: size, 
        height: size,
        backgroundColor: markerColor(scrubbing, selected),
        opacity: selected ? 1.0 : 0.5,
        zIndex: selected ? 520 : 510
    }
    let markerCls = "Marker"
    let label
    if (selected) markerCls += " Sel"
    if (showLabel) {
        let labelText = printDate(date, i18n.language, null)
        if (keyDate != null) labelText += ` (${keyDate.label})`
        label = <div className="DateLabel" style={labelSt}>{ labelText }</div>
    }
    return (
        <>
            <div className={markerCls} style={markerSt} data-date={isoDateString(date)} />
            { label }
        </>
    )
}

ScrubMarker.propTypes = {
    anyDateSelected: PropTypes.bool,
    date: PropTypes.instanceOf(Object),
    dayIndex: PropTypes.number,
    keyDate: PropTypes.object,
    maxIndex: PropTypes.number,
    size: PropTypes.number,
    selected: PropTypes.bool,
    scrubbing: PropTypes.bool
}

function ScrubTimeline({ events, filterDate, walkthroughOpen, onDateSelected }) {
    const [timelineMarkers, setTimelineMarkers] = useState({})
    const [highlightedDate, setHighlightedDate] = useState(null)
    const [days, setDays] = useState(1) // Total span of days showing in timeline
    const [chronDates, setChronDates] = useState([])
    const [scrubbing, setScrubbing] = useState(false)

    useEffect(() => {
        recomputeMarkers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events])

    useEffect(() => {
        setHighlightedDate(filterDate) // Sync local UI date with true date coming from parent
    }, [filterDate])

    const dateWindow = () => {
        let window = {}
        if (Config.scrubberStartDate != null) {
            window.start = dateFromISOString(Config.scrubberStartDate)
        } else {
            window.start = events[0].date
        }
        if (Config.scrubberEndDate != null) {
            window.end = dateFromISOString(Config.scrubberEndDate)
        } else {
            window.end = events[events.length - 1].date
        }
        return window;
    }

    const newMarkerData = (date, start_date, key_date = null) => {
        return {
            dayIndex: dateDiff(start_date, date), 
            count: 0,
            date: date,
            key_date: key_date
        }
    }

    const recomputeMarkers = () => {
        const {start, end} = dateWindow()
        const days = dateDiff(start, end)

        const markers = {} // Date string -> Count
        let dates = []
        if (events.length > 0) {
            events.forEach((e) => {
                const key = printDate(e.date)
                if (markers[key] == null) {
                    markers[key] = newMarkerData(e.date, start)
                    dates.push(isoDateString(e.date))
                }
                markers[key].count += 1
            })    
        }
        Config.scrubberKeyDates.forEach((kd) => {
            let date = dateFromISOString(kd.date)
            markers[printDate(date)] = newMarkerData(date, start, kd)
        })
        setTimelineMarkers(markers)
        setDays(days)
        dates.sort((a, b) => {
            a = a.split('-').join('')
            b = b.split('-').join('')
            return a.localeCompare(b)
        });
        setChronDates(dates)
    }

    const renderMarkers = () => {
        const anyDateSelected = highlightedDate != null;
        return Object.keys(timelineMarkers).map((date) => {
            const m = timelineMarkers[date]
            const sizePct = m.count / events.length
            const [min, max] = Config.scrubMarkerSizeRange;
            const size = percentToMarkerSize(sizePct, min, max);
            const dateSelected = sameDay(m.date, highlightedDate);
            return <ScrubMarker key={m.dayIndex}
                                dayIndex={m.dayIndex} 
                                maxIndex={days}
                                date={m.date}
                                size={size} 
                                keyDate={m.key_date}
                                scrubbing={scrubbing}
                                selected={dateSelected}
                                anyDateSelected={anyDateSelected}
                                />
        })
    }

    const handlePointerMove = (e, forceHandle) => {
        if (scrubbing || forceHandle == true) {
            let el = document.elementFromPoint(e.clientX, e.clientY);
            if (el != null) {
                let isoDate = el.getAttribute("data-date");
                if (isoDate != null) {
                    let d = dateFromISOString(isoDate);
                    if (el.className === "Marker") {
                        updateDate(d)
                    }    
                }
            }    
        }
    }

    const reset = () => {
        onDateSelected(null);
    }

    const updateDate = (d) => {
        setHighlightedDate(d); // Local update (immediate)
        onDateSelected(d);  // Debounced
    }

    const shiftDate = (delta) => {
        let isoDate
        if (highlightedDate != null) {
            let idx = chronDates.indexOf(isoDateString(highlightedDate))
            if (idx == -1) console.log(`Couldn't find ${highlightedDate}`)
            let nextIdx = idx + delta
            if (nextIdx < 0) nextIdx = chronDates.length - 1;
            else if (nextIdx >= chronDates.length) nextIdx = 0;
            isoDate = chronDates[nextIdx]
        } else {
            isoDate = chronDates[0]
        }
        updateDate(dateFromISOString(isoDate))

    }

    let cls = "ScrubTimeline"
    if (walkthroughOpen) cls += " WalkthroughOpen"
    return (
        <div className={cls}>
            <div className="Reset"><a href="#" onClick={reset}><ResetIcon width="20" height="20" /></a></div>
            <div className="Scrubber"
                onPointerDown={(e) => {
                    setScrubbing(true)
                    handlePointerMove(e, true)
                }}
                onPointerUp={() => setScrubbing(false)}
                onPointerLeave={() => setScrubbing(false)}
                onPointerMove={handlePointerMove}
                >
                <div className="ScrubTimelineRel">
                    <div className="Bar"></div>
                    { renderMarkers() }
                </div>
            </div>
            <div className="ShiftButtons">
                <a href="#" className="Prev" onClick={() => shiftDate(-1)}><RightArrow width="20" height="20" /></a>
                <a href="#" className="Next" onClick={() => shiftDate(1)}><RightArrow width="20" height="20" /></a>
            </div>
        </div>
    );
}

ScrubTimeline.defaultProps = {
    events: [],
    top: "10vh",
    filterDate: null
}

ScrubTimeline.propTypes = {
    events: PropTypes.array,
    walkthroughOpen: PropTypes.bool,
    filterDate: PropTypes.instanceOf(DateTime),
    onDateSelected: PropTypes.func
}

export default ScrubTimeline;